// const BASEURL = "https://graceland-backend-new.onrender.com/api";
// const BASEURL = "http://localhost:5000/api";


const BASEURL = "https://graceland-backend-new.onrender.com/api";

export default BASEURL;



// git config --global --unset credential.helper

// https://graceland-memorial-backend.onrender.com/api